import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Spacer,
  Text,
  useColorMode,
} from "@chakra-ui/react"
import { ModalAd } from "../components/ModalAd"
import React, { useEffect, useState } from "react"
import AnimatedCountUpDecimal from "../components/AnimatedCountUpDecimal"
import BannerHyaloron from "../components/choc/BannerHyaloron"
import BannerVeoJadeklinik from "../components/choc/BannerVeoJadeklinik"
import LogoHeadlineTeaser from "../components/choc/LogoHeadlineTeaser"
import LogoHeadlineTeaserVeo from "../components/choc/LogoHeadlineTeaserVEO"
import { Modal3med } from "../components/choc/Modal3med"
import QuadTeaser from "../components/choc/QuadTeaser"
import ScrollcompontentListelement from "../components/choc/ScrollComponentListElementDental"
import ImgTextModule from "../components/ImgTextModule"
import LayoutA from "../components/layout/LayoutWO"
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation"
import ImgMaskReveal from "../DefinitionComponents/Animation-Components/ImgMaskReveal"
import MaskSlidInAnimation from "../DefinitionComponents/Animation-Components/MaskSlideInAnimation"
import RouterLink from "../DefinitionComponents/Utility/Routing/RouterLink"
import InViewTrigger from "../DefinitionComponents/Utility/UX/InViewTrigger"
import ZweiBewerbungen from "../components/ZweiBewerbungen"
import ThreeColumnGridBoxesDocs from "../components/ThreeColumnGridBoxesDocs"
const Dental = () => {
  let { toggleColorMode, ColorMode, setColorMode } = useColorMode()
  useEffect(() => {
    setColorMode(colormodeValue)
  })
  const [isToggledAdModal, setToggleAdModal] = useState(null)

  const [isToggledDVT, setToggleDVT] = useState(null)
  const [viewportHeight, setViewportHeight] = useState(0)
  const [pageHeight, setPageHeight] = useState(0)
  useEffect(() => {
    setViewportHeight(window.innerHeight)
    setPageHeight(document.body.clientHeight)
    // console.log('VIEWPORTHEIGHT: ' + window.innerHeight)
    // console.log('PAGEHEIGHT: ' + document.body.clientHeight)
  }, [])
  const [colormodeValue, changeColorModeValue] = useState("light")
  return (
    <LayoutA
      title={"Arbeite mit uns | Jadeklinik"}
      description={
        "Ein fast perfekter Zahn. Implantate: die schönste und haltbarste Variante des Zahnersatzes. "
      }
    >
      {" "}
      <Container variant="layoutContainer" maxW={"1720px"} mt={12}>
        <ImgTextModule
          src={"dentistBanner.mp4"}
          mt={4}
          px={{ base: 4, md: 12, lg: 4, xl: 0 }}
          delay={0.3}
          duration={0.5}
          threshold={0.3}
          initialY={10}
          initialX={10}
          video={true}
          h={{ base: "80vh", md: "50vh", lg: "50vh", xl: "50vh" }}
          mediaOpacity={0.7}
          bgColor="brand.gray.vdark"
        >
          <Box p={{ base: 0, lg: "8" }}>
            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
              {" "}
              <FadeInAnimation
                threshold={0.4}
                delay={0.4}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text
                  color="white"
                  fontWeight="normal"
                  fontSize={{ base: "md", lg: "md" }}
                >
                  Wir suchen einen
                </Text>
              </FadeInAnimation>
              <FadeInAnimation
                threshold={0.4}
                delay={0.4}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text
                  color="white"
                  fontWeight="bold"
                  fontSize={{ base: "2xl", lg: "4xl" }}
                >
                  Mund-Kiefer-Gesichtschirurgen
                </Text>
              </FadeInAnimation>
              <FadeInAnimation
                threshold={0.4}
                delay={0.4}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text
                  color="white"
                  fontWeight="bold"
                  maxW={{ base: "100%", lg: "20%" }}
                  fontSize={{ base: "md", lg: "md" }}
                >
                  Als Angestellter oder Partner in einer der führenden
                  Kieferchirurgie Praxen in Nordwestdeutschland.
                </Text>
              </FadeInAnimation>
              <Flex flexDirection={{ base: "column", md: "row" }}>
                <Button
                  mt={4}
                  backgroundColor="brand.violet.primary"
                  variant="solid"
                  color="white"
                  onClick={() => setToggleAdModal(true)}
                  _hover={{
                    backgroundColor: "brand.violet.medium",
                    color: "white",
                  }}
                >
                  Mehr Informationen & Video
                </Button>
                <FadeInAnimation
                  threshold={0.4}
                  delay={0.5}
                  duration={1.4}
                  initialX={0}
                  initialY={10}
                >
                  <RouterLink isExternal={false} href="/kontakt">
                    <Button mt={4} ml={{ base: 0, md: 4 }} variant="solid">
                      Kontakt
                    </Button>
                  </RouterLink>
                </FadeInAnimation>
              </Flex>
            </FadeInAnimation>
          </Box>
        </ImgTextModule>
      </Container>
      <ModalAd
        isToggled={isToggledAdModal}
        setToggle={setToggleAdModal}
      ></ModalAd>
      <Container variant="layoutContainer" my={24} maxW={"1720px"}>
        <ImgTextModule
          src={"Short1-Master-003.mp4"}
          delay={0.3}
          duration={0.5}
          threshold={0.3}
          initialY={10}
          initialX={10}
          video={true}
          px={{ base: 0, md: 12, lg: 4, xl: 0 }}
          mediaOpacity={0.8}
          bgColor="black"
        >
          <Box p={{ base: 0, lg: "8" }}>
            <Flex
              minH="56vh"
              mt={0}
              flexDirection={"column"}
              justifyContent={"flex-end"}
            >
              <Box>
                <FadeInAnimation
                  threshold={0.4}
                  delay={0.4}
                  duration={0.7}
                  initialX={0}
                  initialY={-10}
                >
                  <Text
                    color="brand.tan.ulight"
                    fontWeight="bold"
                    lineHeight={1}
                    fontSize={{ base: "6xl", lg: "8xl" }}
                  >
                    Ein tolles Team erwartet Dich.
                  </Text>
                </FadeInAnimation>
                <MaskSlidInAnimation threshold={1} duration={1}>
                  <Heading
                    mr={32}
                    mt={2}
                    color={"white"}
                    fontSize={{ lg: "2xl", md: "lg", base: "lg" }}
                    fontWeight="normal"
                    w={"100%"}
                    letterSpacing="tight"
                  >
                    Gutes Arbeitsklima mit einem Team,
                  </Heading>
                </MaskSlidInAnimation>
                <MaskSlidInAnimation threshold={1} duration={1}>
                  <Heading
                    mr={32}
                    mt={2}
                    color={"white"}
                    fontSize={{ lg: "2xl", md: "lg", base: "lg" }}
                    fontWeight="normal"
                    w={"100%"}
                    letterSpacing="tight"
                  >
                    das den Praxisalltag rockt!
                  </Heading>
                </MaskSlidInAnimation>
                <FadeInAnimation
                  threshold={0.4}
                  delay={0.5}
                  duration={1.4}
                  initialX={0}
                  initialY={10}
                >
                  <RouterLink isExternal={false} href="/bewerbung">
                    <Button mt={4} variant="ghost" color="white">
                      Jetzt bewerben!
                    </Button>
                  </RouterLink>
                </FadeInAnimation>
              </Box>
            </Flex>
          </Box>
        </ImgTextModule>
      </Container>
      <LogoHeadlineTeaserVeo
        py={0}
        headline="Gemeinsam"
        subheadline="Zahn für Zahn."
      />
      <Container variant="layoutContainer" minH="56vh">
        <ImgTextModule
          src={"shortmaster4.mp4"}
          my={4}
          px={{ base: 4, md: 12, lg: 4, xl: 0 }}
          delay={0.3}
          duration={0.5}
          threshold={0.3}
          initialY={10}
          initialX={10}
          video={true}
          minH="56vh"
          mediaOpacity={0.7}
          bgColor="brand.gray.vdark"
        >
          <Box p={{ base: 0, lg: "8" }}>
            <InViewTrigger threshold={0.1} triggerOnce={false}>
              {inView => (
                <AnimatedCountUpDecimal
                  inView={inView}
                  unit=" "
                  fontSizeUnit="lg"
                  count={3}
                  fontSize={"12rem"}
                  duration={1}
                  decimal={false}
                  decimalDuration={2}
                  decimalCount={0}
                  unitSpacing={3}
                  numberColor={"white"}
                  textColor={"white"}
                  cellHeight={"12rem"}
                  letterSpacing={"-0.2rem"}
                ></AnimatedCountUpDecimal>
              )}
            </InViewTrigger>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color="white"
                fontWeight="normal"
                fontSize={{ base: "2xl", lg: "2xl" }}
              >
                Offene Stellen
              </Text>
            </FadeInAnimation>
            <Flex mt={0}>
              <MaskSlidInAnimation threshold={1} duration={1}>
                <Heading
                  mr={32}
                  mt={2}
                  color={"white"}
                  fontSize={{ lg: "xl", md: "lg", base: "lg" }}
                  fontWeight="normal"
                  w={"100%"}
                  letterSpacing="tighter"
                >
                  Von Auszubildenden bis hin zu OP-Spezialisten
                </Heading>
              </MaskSlidInAnimation>
            </Flex>
            <FadeInAnimation
              threshold={0.4}
              delay={0.5}
              duration={1.4}
              initialX={0}
              initialY={10}
            >
              <RouterLink isExternal={false} href="/bewerbung">
                <Button mt={4} variant="ghost" color="white">
                  Bewirb Dich!
                </Button>
              </RouterLink>
            </FadeInAnimation>
          </Box>
        </ImgTextModule>
        <ZweiBewerbungen />
      </Container>
      <LogoHeadlineTeaserVeo
        py={24}
        headline="Unsere Ärzte freuen sich über Dich."
        subheadline="Tolles Team. Tolle Leistungen. Tolle Zukunft."
      />
      <ThreeColumnGridBoxesDocs />
      <Container variant="layoutContainer" px={12} pt={12}>
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Wir für Dich."
          text1="Und Du mit uns."
          text2="Die Praxis investiert in dich und ermöglicht Fort- und Weiterbildung auf ihre Kosten.
          "
        />
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Fair Play."
          text1="Nicht nur Luft und Liebe."
          text2="Neben einem fairen Gehalt gibt es Extras wie Tank- und Mobilfunkgutscheine."
        />
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Bonus folgt."
          text1="Zukunft mit uns gemeinsam."
          text2="Du wirst nicht nur heute, sondern auch in Zukunft mit regelmäßigen Bonuszahlungen belohnt.
          "
        />
      </Container>
      <Container variant="layoutContainer" minH="56vh">
        <ImgTextModule
          src={"Short1-Master-002.mp4"}
          mt={4}
          px={{ base: 4, md: 12, lg: 4, xl: 0 }}
          delay={0.3}
          duration={0.5}
          threshold={0.3}
          initialY={10}
          initialX={10}
          video={true}
          h="56vh"
          mediaOpacity={0.7}
          bgColor="brand.gray.vdark"
        >
          <Box p={{ base: 0, lg: "8" }}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color="white"
                fontWeight="normal"
                fontSize={{ base: "2xl", lg: "2xl" }}
              >
                Anspruchsvoll dabei
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color="white"
                fontWeight="normal"
                fontSize={{ base: "3xl", lg: "6xl" }}
              >
                Und mit echten Zukunftschancen.
              </Text>
            </FadeInAnimation>

            <Flex
              mt={0}
              flexWrap="wrap"
              flexDirection={{ base: "flex-column", md: "flex-row" }}
            >
              <MaskSlidInAnimation threshold={1} duration={1}>
                <Heading
                  mr={32}
                  mt={2}
                  color={"white"}
                  fontSize={{ lg: "xl", md: "lg", base: "lg" }}
                  fontWeight="normal"
                  w={{ base: "100%", md: "50%" }}
                  letterSpacing="tight"
                >
                  Allround Talente: Wir entwickeln uns mit Dir. Und wir schauen
                  gemeinsam wo Deine Stärken sind.
                </Heading>
              </MaskSlidInAnimation>
              <MaskSlidInAnimation threshold={1} duration={1}>
                <Heading
                  mr={32}
                  mt={2}
                  color={"white"}
                  fontSize={{ lg: "xl", md: "lg", base: "lg" }}
                  fontWeight="normal"
                  w={{ base: "100%", md: "50%" }}
                  letterSpacing="tight"
                >
                  Lass uns gemeinsam in einem Gespräch herausfinden, wo unsere
                  Möglichkeiten und Deine Stärken ein perfektes Match sind.
                </Heading>
              </MaskSlidInAnimation>

              <FadeInAnimation
                threshold={0.4}
                delay={0.5}
                duration={1.4}
                initialX={0}
                initialY={10}
              >
                <RouterLink isExternal={false} href="/bewerbung">
                  <Button mt={4} variant="ghost" color="white">
                    Kontakt - Bewerbung
                  </Button>
                </RouterLink>
              </FadeInAnimation>
            </Flex>
          </Box>
        </ImgTextModule>
      </Container>
      <Container variant="layoutContainer" mt={0.5} py={12} mb={0.5} px={32}>
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Fit"
          text1="Nicht nur im Job"
          text2="Bleib fit und aktiv dank unserer Firmenfitnessprogramme! "
        />
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Flexibel"
          text1="Nicht nur Du. Wir auch."
          text2="Mehr Freiheit für dein Privatleben - unsere flexible Urlaubsplanung macht's möglich."
        />
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Übergreifend"
          text1="Bei uns wirst du zum Allrounder: "
          text2="Wir bieten ein breites Spektrum an Tätigkeiten von Zahnmedizin bis plastisch-ästhetischer Chirurgie im Kopf- und Halsbereich."
        />
      </Container>
      <ImgTextModule
        src={"short-master005.mp4"}
        mt={4}
        px={{ base: 4, md: 12, lg: 4, xl: 0 }}
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        h="76vh"
        rounded="none"
        mediaOpacity={0.7}
        bgColor="brand.gray.vdark"
      >
        <Box p={{ base: 0, lg: "8" }}>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}
          >
            <Text
              color="white"
              fontWeight="normal"
              fontSize={{ base: "xl", lg: "xl" }}
            >
              Was macht eine
            </Text>
          </FadeInAnimation>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}
          >
            <Text
              color="white"
              fontWeight="bold"
              fontSize={{ base: "5xl", lg: "6xl" }}
            >
              MKG-Praxis ?
            </Text>
          </FadeInAnimation>

          <Flex
            mt={0}
            flexWrap="wrap"
            flexDirection={{ base: "column", md: "row" }}
            justifyContent="start"
          >
            <Box>
              <MaskSlidInAnimation threshold={1} duration={1}>
                <Heading
                  maxW={{ base: "100%", md: "50%" }}
                  mr={32}
                  mt={2}
                  color={"white"}
                  fontSize={{ lg: "2xl", md: "xl", base: "xl" }}
                  fontWeight="normal"
                  w={{ base: "100%", md: "50%" }}
                  letterSpacing="tight"
                >
                  Die Mund-, Kiefer- und Gesichtschirurgie beschäftigt sich mit
                  der Diagnose, Behandlung und Prävention von Erkrankungen und
                  Verletzungen des Kiefers und der Gesichtsregion.
                </Heading>
              </MaskSlidInAnimation>
            </Box>
            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
              <Button
                mt={4}
                onClick={() => setToggleDVT(true)}
                variant="ghost"
                color="white"
              >
                Mehr erfahren
              </Button>
            </FadeInAnimation>
            <Spacer />
          </Flex>
        </Box>
      </ImgTextModule>
      <Modal3med isToggled={isToggledDVT} setToggle={setToggleDVT}></Modal3med>
      <Container variant="layoutContainer" mt={0.5} py={12} mb={0.5}>
        <BannerVeoJadeklinik />
      </Container>
      <QuadTeaser />
    </LayoutA>
  )
}

export default Dental
